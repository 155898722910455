import { Box, Button, Dialog, Stack } from "@mui/material";
import Select from "components/ui/select";
import useApi from "hooks/useApi";
import { useEffect } from "react";
import { TfiExchangeVertical } from "react-icons/tfi";
import { updateProjectCustomerStatus } from "../api/projectCustomer";
import { toast } from "react-toastify";
import MultiSelect from "components/ui/multiSelect";

const ComfirmTransfer = ({
  open,
  setOpen,
  setStaffTransferData,
  staffTransferData,
  assignees,
  setReload,
}) => {
  let { fetchData, loading } = useApi();
  useEffect(() => {
    setStaffTransferData({
      ...staffTransferData,
      transfer_to_id: [],
    });
  }, []);
  let handleTransfer = async () => {
    const endpoint = {
      method: "post",
      url: `/api/admin/project-customer/change/assignee/${open?.row_id}`,
      data: {
        user_id: staffTransferData?.transfer_to_id,
      },
    };

    let res = await fetchData(endpoint);

    if (res?.success) {
      let Nres = await updateProjectCustomerStatus({
        body_data: {
          project_customer_id: open?.row_id,
          project_customer_status_id: open?.status_id,
        },
      });
      if (Nres?.success) {
        setOpen(false);
        setReload((prev) => !prev);
      } else {
        toast.error(Nres.message);
      }
    } else {
      toast.error(res.message);
    }
  };
  
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& div": {
            borderRadius: "8px",
          },
        }}
      >
        <Box sx={{ width: "400px" }}>
          <Box
            sx={{
              paddingTop: "30px",
              paddingBottom: "5px",
              fontSize: "30px",
              fontWeight: "500",
              color: "#0cb367",
              textAlign: "center",
            }}
          >
            <TfiExchangeVertical />
          </Box>
          <Box
            sx={{
              marginTop: "-5px",
              p: "20px",
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            To inactive the project please transfer the project to an admin
          </Box>
          <Box sx={{ backgroundColor: "#f8f8f8", paddingBottom: "5px" }}>
            <Box sx={{ padding: "15px 15px 0" }}>
              <Box sx={{ p: "5px 0 10px" }}>
                <MultiSelect
                  label="Select Admin"
                  name="type"
                  options={assignees}
                  selectProps={{ id: "user_id", label: "name" }}
                  value={staffTransferData?.transfer_to_id}
                  onChange={(value) =>
                    setStaffTransferData({
                      ...staffTransferData,
                      transfer_to_id: value || [],
                    })
                  }
                />
              </Box>
            </Box>
            <Stack
              // direction="row"
              gap="10px"
              justifyContent="center"
              padding="15px"
            >
              <Button
                fullWidth
                onClick={handleTransfer}
                disabled={!staffTransferData?.transfer_to_id?.length || loading}
                variant="solid"
              >
                Transfer
              </Button>
              <Button
                fullWidth
                onClick={() => {
                  setStaffTransferData({
                    ...staffTransferData,
                    transfer_to_id: [],
                  });
                  setOpen(false);
                }}
              >
                Skip
              </Button>
            </Stack>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ComfirmTransfer;
