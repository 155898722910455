import { PrintRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import UNIVERSAL from "config";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import ReactLoading from "react-loading";

const Profile = ({ profile, loading }) => {
  const site_logo = useSelector((state) => state?.base?.site_info?.site_logo);
  let currentCurrencySymbol = profile?.currency_symbol;
  let currentCurrencyName = profile?.currency_name;

  const uni_table_columns = [
    {
      id: "university_commission_date",
      label: "Date",
      minWidth: 100,
    },
    {
      id: "university_commission_due_date",
      label: "Due Date",
      minWidth: 100,
    },
    {
      id: "university_commission_amount",
      label: "Amount",
      minWidth: 100,
    },
  ];

  const uni_table_rows = profile?.university_commissions || [];
  const symbol = profile?.symbol;

  const printableContentRef = useRef();

  // conditional useState
  const [uni_print, setUniPrint] = useState(true);

  return loading ? (
    <Box
      sx={{
        height: "calc(100vh - 162px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading
        type="cylon"
        color="#405189"
        height={"85px"}
        width={"100px"}
      />
    </Box>
  ) : (
    <Box m="-20px">
      <ReactToPrint
        documentTitle={profile?.invoice_number}
        onBeforeGetContent={async () => {
          setUniPrint(true);
        }}
        onAfterPrint={async () => {
          setUniPrint(true);
        }}
        trigger={() => (
          <Button
            variant="outlined"
            startIcon={<PrintRounded />}
            sx={{
              position: "absolute",
              right: "20px",
              top: "20px",
            }}
          >
            Print
          </Button>
        )}
        content={() => printableContentRef.current}
      />
      <Box ref={printableContentRef} p={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "10px",
            mb: "35px",
          }}
        >
          <img
            src={UNIVERSAL.BASEURL + site_logo}
            alt=""
            style={{
              maxHeight: "100px",
              maxWidth: "240px",
            }}
          />
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                justifyContent: "space-between",
              }}
            >
              <Typography textTransform="uppercase">Student Id:</Typography>
              <Typography sx={{ textAlign: "end" }}>
                {profile?.student_id}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                justifyContent: "space-between",
              }}
            >
              <Typography textTransform="uppercase">Reference Name:</Typography>
              <Typography sx={{ textAlign: "end" }}>
                {profile?.reference_name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                justifyContent: "space-between",
              }}
            >
              <Typography textTransform="uppercase">Course Name:</Typography>
              <Typography sx={{ textAlign: "end" }}>
                {profile?.course_name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                justifyContent: "space-between",
              }}
            >
              <Typography textTransform="uppercase">Customer Name:</Typography>
              <Typography sx={{ textAlign: "end" }}>
                {" "}
                {profile?.customer_name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                justifyContent: "space-between",
              }}
            >
              <Typography textTransform="uppercase">
                University Name:
              </Typography>
              <Typography sx={{ textAlign: "end" }}>
                {" "}
                {profile?.university_name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                justifyContent: "space-between",
              }}
            >
              <Typography textTransform="uppercase">Intake Name:</Typography>
              <Typography sx={{ textAlign: "end" }}>
                {" "}
                {profile?.intake_name}
              </Typography>
            </Box>
          </Box>
        </Box>

        {uni_print === true && (
          <Box
            sx={{
              display: "grid",
              width: "100%",
            }}
          >
            <Box
              sx={{
                margin: "20px 0",
              }}
            >
              <TableContainer sx={{ mt: "0px" }}>
                <Table stickyHeader>
                  <TableBody>
                    <TableRow>
                      {uni_table_columns.map((column) => (
                        <TableCell
                          sx={{ fontWeight: "500" }}
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                    {uni_table_rows?.map((row) => {
                      return (
                        <TableRow hover role="checkbox" key={row.id}>
                          {uni_table_columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "fee" ? (
                                  value?.name
                                ) : column.id === "tax" ? (
                                  <Typography color="#2fb42f" variant="body2">
                                    {"+ " + symbol + value}
                                  </Typography>
                                ) : column.id === "discount" ? (
                                  <Typography color="#ff3535" variant="body2">
                                    {"- " + symbol + value}
                                  </Typography>
                                ) : column.id === "total" ? (
                                  <Typography color="#7171ff" variant="body2">
                                    {symbol + value}
                                  </Typography>
                                ) : column.id === "subtotal" ? (
                                  <Typography variant="body2">
                                    {symbol + value}
                                  </Typography>
                                ) : column?.id ===
                                  "university_commission_amount" ? (
                                  currentCurrencySymbol +
                                  (value || 0) +
                                  " " +
                                  currentCurrencyName
                                ) : (
                                  value || "-"
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box
              sx={{
                margin: "0px 0 0 auto",
                padding: "20px 30px",
                border: "1px solid #caddff",
                bgcolor: "#fbfcff",
                width: "300",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "200px auto",
                }}
              >
                <Typography align="left" fontSize="16px">
                  Total Commission
                </Typography>
                <Typography align="right" fontSize="16px">
                  {currentCurrencySymbol}
                  {uni_table_rows.reduce(
                    (acc, curr) => acc + curr.university_commission_amount,
                    0
                  )}{" "}
                  {currentCurrencyName}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Profile;
