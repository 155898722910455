import { TabList, TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import { Box, Tab } from "@mui/material";
import RightDrawer from "components/rightDrawer/RightDrawer";
import { useState } from "react";
import Profile from "./profile/Profile";

import NotAuthorized from "components/notAuthorized";
import useApi from "hooks/useApi";
import useQueries from "hooks/useQueries";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import CommisionAll from "./profile/CommisionAll";
import UniversityCommission from "./university-commission/Commission";
import StudentCommission from "./student-commission/Commission";

let commonColumn = [
  {
    id: "student_id",
    label: "Student ID",
    minWidth: 100,
  },
  {
    id: "customer_name",
    label: "Studant Name",
    minWidth: 100,
  },
  {
    id: "intake_name",
    label: "Intake Name",
    minWidth: 100,
  },
  {
    id: "course_name",
    label: "Course Name",
    minWidth: 100,
  },
];

const table_columns_university = [
  ...commonColumn,

  {
    id: "university_commission_amount",
    label: "Amount",
    minWidth: 100,
  },
  {
    id: "university_commission_due_date",
    label: "Due Date",
    minWidth: 100,
  },
  {
    id: "university_commission_date",
    label: "Date",
    minWidth: 100,
  },
];

const ViewReference = ({ openDrawer, setOpenDrawer, permissions }) => {
  const { row_id, mod_id, setQueries } = useQueries();
  const { fetchData, loading } = useApi();
  const [profile, setProfile] = useState({});
  const [value, setValue] = useState(mod_id || "1");

  const handleChange = (event, newValue) => {
    setQueries(`id=${row_id}&m=${newValue}`);
    setValue(newValue);
  };

  useEffect(() => {
    const getProfile = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/invoice/university-reference/show/${row_id}`,
      };
      const { success, data } = await fetchData(endpoint, false);
      if (success) setProfile(data);
    };
    getProfile();
  }, []);

  const getcurrency = useSelector((state) => state?.base?.base_data);

  let symbol = getcurrency?.currencies?.find(
    (c) => c?.id === getcurrency?.settings?.app?.currency_id
  )?.symbol;

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "900px",
        headingLeft:
          "#" +
          row_id +
          `${profile?.invoice_number ? ` - ${profile?.invoice_number}` : ""}`,
        headingRight: "",
      }}
    >
      <Box sx={{ width: "100%", typography: "body1", overflowX: "hidden" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="View References" value="1" />
              <Tab label="View  University All Commissions" value="3" />
              <Tab label="University Commissions" value="4" />
              <Tab label="Tuition Fees" value="5" />
            </TabList>
          </Box>
          <Box
            sx={{
              position: "relative",
              height: "calc(100vh - 114px)",
            }}
          >
            <TabPanel value="1">
              {permissions["show"] ? (
                <Profile profile={profile} loading={loading} />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>

            <TabPanel value="3">
              {permissions["show"] ? (
                <CommisionAll
                  table_columns={table_columns_university}
                  commission_type="university_commissions"
                  university_name={profile?.university_name}
                  uni_or_ref_id={profile?.university_id}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>

            <TabPanel value="4">
              <UniversityCommission profile={profile} symbol={symbol} />
            </TabPanel>

            <TabPanel value="5">
              <StudentCommission profile={profile} symbol={symbol} />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </RightDrawer>
  );
};

export default ViewReference;
