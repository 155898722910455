import * as yup from "yup";

const validationSchema = yup.object({
  customer_id: yup.string("").required("Customeris Required"),
  currency_id: yup.string("").required("Currency is Required"),
  university_id: yup.string("").required("University is Required"),
  status: yup.string("").required("Status is Required"),
});

export default validationSchema;
