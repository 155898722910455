import { Attachment, Send } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  InputBase,
  Skeleton,
  Typography,
} from "@mui/material";
import PopoverAG from "components/ui/popoverAG";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useDidMountEffect from "hooks/useDidMountEffect";
import Pusher from "pusher-js";
import { memo, useEffect, useMemo, useState } from "react";
import { IoLogoWechat } from "react-icons/io5";
import { useSelector } from "react-redux";
import * as yup from "yup";
import Conversation from "./Conversation";
import { useSearchParams } from "react-router-dom";
const Chat = ({
  route,
  isCustomer,
  customer_id,
  user_id,
  owner_id,
  projectInfo,
  matchHeight = null,
  projectName = null,
}) => {
  const settings = useSelector((state) => state?.base?.base_data?.settings);
  let [toogle, setToggle] = useState(true);
  let [msg, setMsgs] = useState({});
  let [currChat, setCurrChat] = useState({});
  let [projects, setProjects] = useState([]);
  let [selectedProject, setSelectedProject] = useSearchParams(
    projectInfo || {}
  );
  const selectProjFromQuery = {
    related_id: selectedProject.get("related_id"),
    project_id: selectedProject.get("project_id"),
    project_name: selectedProject.get("project_name"),
  };
  let [reload, setReload] = useState(null);
  const lastMessage = useMemo(() => {
    const messages = msg.data || [];
    return messages[messages.length - 1];
  }, [msg.data]);

  let { loading, fetchData } = useApi();
  useEffect(() => {
    async function getAllMessages() {
      let endpoint = {
        method: "post",
        url: `/api/${route}/chat/list`,
        data: {
          related_to: "project",
          related_id: selectProjFromQuery?.related_id,
        },
      };

      const result = await fetchData(endpoint, false);
      if (result.success) {
        setMsgs(result?.data);
      }
    }
    selectProjFromQuery?.related_id && getAllMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectProjFromQuery?.related_id]);

  let { fetchData: realTimeListAPiCall } = useApi();

  useDidMountEffect(() => {
    async function getAllMessages() {
      let endpoint = {
        method: "post",
        url: `/api/${route}/chat/list`,
        data: {
          related_to: "project",
          related_id: selectProjFromQuery?.related_id,
        },
      };

      const result = await realTimeListAPiCall(endpoint, false);
      if (result.success) {
        setMsgs(result?.data);
      }
    }
    selectProjFromQuery?.related_id && getAllMessages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  let { loading: msgSendLoading, fetchData: sendMsgLoading } = useApi();

  let onSubmit = async (value, action) => {
    let data = {
      chat_id: currChat?.id || "",
      message: value?.msg,
      file: value?.file,
      user_id: user_id || "",
      customer_id: customer_id || "",
      related_to: "project",
      related_id: selectProjFromQuery?.related_id,
    };
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    let endpoint;
    if (currChat?.id) {
      endpoint = {
        method: "post",
        url: `/api/${route}/chat/update`,
        data: formData,
      };
    } else {
      endpoint = {
        method: "post",
        url: `/api/${route}/chat/create`,
        data: formData,
      };
    }

    let result = await sendMsgLoading(endpoint, false);
    if (result.success) {
      if (currChat?.id) {
        setMsgs((prev) => ({
          ...prev,
          data: prev?.data?.map((msg) =>
            msg?.id === currChat?.id ? result.data : msg
          ),
        }));
        setCurrChat({});
      } else {
        setMsgs((prev) => ({ ...prev, data: [...prev.data, result.data] }));
      }
    }

    action.resetForm();
  };

  let formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      msg: currChat?.message || "",
      file: null,
    },
    validationSchema: yup.object({
      msg: yup.string().required().trim(),
    }),
    onSubmit,
  });
  let { fetchData: seenApi } = useApi();

  const handleMessageSeen = async (e) => {
    let endpoint = {
      method: "post",
      url: `/api/${route}/chat/action`,
      data: {
        chat_id: lastMessage?.id,
        action: "seen",
      },
    };
    await seenApi(endpoint, false);
  };

  useEffect(() => {
    handleMessageSeen();
  }, [lastMessage?.id]);

  useEffect(() => {
    const pusher = new Pusher(settings?.notification?.pusher_key || "", {
      cluster: settings?.notification?.pusher_cluster || "",
      useTLS: false,
      forceTLS: false,
    });

    const channel = pusher.subscribe("chatevent");
    channel.bind("chatevent", function (data) {
      if (
        (data.action === "create" ||
          data.action === "Update" ||
          data.action === "delete") &&
        data?.user_id === owner_id
      ) {
        return;
      } else {
        setReload((prev) => !prev);
      }
    });
    return () => {
      pusher.unsubscribe("chatevent");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      formik.handleSubmit();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        mt: "10px",
        height: `calc(100vh - ${matchHeight || "85px"})`,
        mx: "10px",
        border: "1px solid #ddd",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      {isCustomer && (
        <ProjectSidebar
          {...{
            setProjects,
            route,
            customer_id,
            selectProjFromQuery,
            setSelectedProject,
            projects,
            formik,
            setCurrChat,
            toogle,
            setToggle,
          }}
        />
      )}
      <Box
        sx={{
          flexShrink: 0,
          flexGrow: 1,
          borderTopLeftRadius: "0",
          borderBottomLeftRadius: "0",
          position: "relative",
        }}
      >
        {(isCustomer &&
          !projects?.some(
            (item) => item?.project?.id == selectProjFromQuery?.project_id
          )) ||
        (!isCustomer && !selectProjFromQuery?.project_id) ? (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <IoLogoWechat fontSize={"60px"} fill="#405189" />
          </Box>
        ) : (
          <>
            {loading ? (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    p: "10px 20px",
                    borderBottom: "1px solid #ddd",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                    minHeight: "60px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <IconButton
                      sx={{ display: { xs: "block", md: "none" } }}
                      size="small"
                      onClick={() => {
                        setToggle((prev) => !prev);
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Typography variant="h4">
                      {projects?.find(
                        (item) =>
                          item?.project?.name ===
                          selectProjFromQuery?.project_name
                      )?.project?.name || projectName}
                    </Typography>
                  </Box>

                  <PopoverAG items={msg?.assignees} />
                </Box>
                <Box
                  // onFocus={handleMessageSeen}
                  tabIndex="0"
                  p="20px"
                  pt="0"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "calc(100% - 50px)",
                  }}
                >
                  <Conversation
                    owner_id={owner_id}
                    msg={msg?.data}
                    lastMessage={lastMessage}
                    isCustomer={isCustomer}
                    setMsgs={setMsgs}
                    setCurrChat={setCurrChat}
                    route={route}
                  />

                  <Box>
                    <form onSubmit={formik.handleSubmit}>
                      <InputBase
                        id="target"
                        sx={{
                          width: "100%",
                          borderTop: "1px solid #222",
                          py: "10px",
                        }}
                        placeholder="Type something..."
                        multiline
                        value={formik.values.msg}
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyPress}
                        name="msg"
                        rows={3}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex", gap: "5px" }}>
                          <IconButton variant="contained" component="label">
                            <input
                              name="file"
                              type="file"
                              hidden
                              onChange={(e) =>
                                formik.setFieldValue("file", e.target.files[0])
                              }
                            />
                            <Attachment />
                          </IconButton>
                          {formik.values.file ? (
                            <Box>
                              <Chip
                                label={formik.values.file.name}
                                deleteIcon={<DeleteIcon />}
                                variant="outlined"
                                onDelete={() =>
                                  formik.setFieldValue("file", null)
                                }
                              />
                            </Box>
                          ) : (
                            currChat?.file && (
                              <Box>
                                <Chip
                                  label={currChat?.file_name}
                                  variant="outlined"
                                />
                              </Box>
                            )
                          )}
                        </Box>
                        <Button
                          type="submit"
                          disabled={
                            !(formik.isValid && formik.dirty) || msgSendLoading
                          }
                          variant="solid"
                          endIcon={<Send />}
                          sx={{
                            borderRadius: "100px",
                          }}
                        >
                          {currChat?.id ? "Edit" : "Send"}
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default memo(Chat);

const ProjectSidebar = ({
  projects,
  setProjects,
  route,
  customer_id,
  selectProjFromQuery,
  setSelectedProject,
  formik,
  setCurrChat,
  toogle,
  setToggle,
}) => {
  let { loading: fecthProjectLoading, fetchData: fecthProject } = useApi();

  useEffect(() => {
    async function getAllProjects() {
      let endpoint = {
        method: "post",
        url: `/api/${route}/related_id/list`,
        data: {
          related_to: "project",
          customer_id,
        },
      };

      const result = await fecthProject(endpoint, false);
      if (result.success) {
        setProjects(result?.data);
      }
    }
    getAllProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProjectSelection = (item) => {
    setSelectedProject({
      project_name: item?.project?.name,
      project_id: item?.project_id,
      related_id: item?.related_id,
    });
    formik.resetForm();
    setCurrChat({});
  };
  return (
    <Box
      sx={{
        width: "300px",
        borderRight: "1px solid #ddd",
        height: { xs: "calc(100vh - 87px)", md: "100%" },
        position: { xs: "absolute", md: "static" },
        borderRadius: { xs: "10px", md: 0 },
        left: "11px",
        zIndex: 100,
        backgroundColor: "#fff",
        transform: {
          xs: toogle ? "translateX(0%)" : "translateX(-200%)",
          md: "translateX(0)",
        },
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          gap: "10px",
          p: "10px 20px",
          borderBottom: "1px solid #ddd",
          minHeight: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">Select Your Projects</Typography>
        {selectProjFromQuery?.project_id && (
          <IconButton
            sx={{ display: { xs: "block", md: "none" } }}
            size="small"
            onClick={() => {
              setToggle((prev) => !prev);
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>

      <Box
        sx={{
          height: "calc(100% - 60px)",
          overflowY: "auto",
        }}
        className="disable_bar"
      >
        <>
          {fecthProjectLoading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                padding: "10px",
              }}
            >
              {[...Array(5)].map((_, i) => (
                <Skeleton height={50} />
              ))}
            </Box>
          ) : (
            projects.map((project) => {
              return (
                <Box
                  role="button"
                  onClick={() => {
                    handleProjectSelection(project);
                    setToggle((prev) => !prev);
                  }}
                  key={project?.project?.id}
                  sx={{
                    p: "10px",
                    textAlign: "left",
                    borderBottom: "1px solid #ddd",
                    cursor: "pointer",
                    ...(selectProjFromQuery?.project_id ==
                      project?.project_id && {
                      borderLeft: "4px solid #ddd",
                    }),
                  }}
                  fullWidth
                >
                  <Typography variant="h6">{project?.project?.name}</Typography>
                  {/* <Typography variant="h4">{project.name}</Typography> */}
                </Box>
              );
            })
          )}
        </>
      </Box>
    </Box>
  );
};
