import { Add } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import RightDrawer from "components/rightDrawer/RightDrawer";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import {
  createCustomer,
  updateCustomer,
} from "features/customers/api/customer";
import { processEducationForm } from "utils/processData";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import useQueries from "hooks/useQueries";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import handleError from "utils/handleError";
import validationSchema from "../../schema";
import ELPCForms from "./ELPCForms";
import EduFields from "./EduFields";
import JobFroms from "./JobFroms";

const AddEdit = ({
  persistedData,
  setPersistedData,
  openDrawer,
  setOpenDrawer,
  sources,
  assignees,
  countries,
  universities,
  courses,
  institutes,
  subjects,
  setAllCustomers,
  allCustomers,
}) => {
  const { row_id } = useQueries();
  const { loading, fetchData } = useApi();
  const [currCustomer, setCurrCustomer] = useState(row_id ? {} : persistedData);
  const isUpdating = row_id ? true : false;
  const { user, role } = useSelector((state) => state?.auth?.user_details);
  const { updateBase } = useBase();

  const handleClose = (reset) => {
    setPersistedData(row_id ? {} : reset ? {} : formik?.values);
    setOpenDrawer();
  };

  useEffect(() => {
    const getFullCustomer = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/customer/show/${row_id}`,
      };
      const result = await fetchData(endpoint, false);
      result.success && setCurrCustomer(result?.data);
    };
    row_id && getFullCustomer();
  }, []);

  const onSubmit = async (data) => {
    const result = await fetchData(
      isUpdating ? updateCustomer(currCustomer?.id, data) : createCustomer(data)
    );
    if (result?.success) {
      if (isUpdating) {
        setAllCustomers(
          allCustomers.map((customer) =>
            customer.id === currCustomer.id
              ? { ...currCustomer, ...result?.data }
              : customer
          )
        );
      } else {
        updateBase(["customers"]);
        if (
          role === "Admin" ||
          result?.data?.assignees?.find((a) => a.user_id === user.id)
        ) {
          setAllCustomers([{ ...result?.data, notes: [] }, ...allCustomers]);
        }
      }
      handleClose("reset");
    } else {
      handleError(result, formik);
    }
  };

  const filterCourses = (courses) => {
    return courses?.map((course) =>
      (({
        course_id,
        overall,
        listening,
        reading,
        writing,
        speaking,
        literacy,
        comprehension,
        conversation,
        production,
      }) => ({
        course_id,
        overall,
        listening,
        reading,
        writing,
        speaking,
        literacy,
        comprehension,
        conversation,
        production,
      }))(course)
    );
  };

  const filterJobs = (jobs) => {
    return jobs?.map((job) =>
      (({
        company_address,
        company_name,
        duration,
        end_date,
        join_date,
        title,
      }) => ({
        company_address,
        company_name,
        duration,
        end_date,
        join_date,
        title,
      }))(job)
    );
  };

  // initial values depending on edit mode or create mode
  const initialValues = {
    educations: currCustomer?.educations
      ? processEducationForm(currCustomer?.educations)
      : [],
    password: currCustomer?.password || "",
    name: currCustomer?.name ? currCustomer.name : "",
    phone: currCustomer?.phone ? currCustomer.phone : "",
    email: currCustomer?.email ? currCustomer.email : "",
    source_id: currCustomer?.source_id ? currCustomer.source_id : null,
    assignees: currCustomer?.id
      ? currCustomer?.assignees?.map((assignee) => assignee?.user_id)
      : [user?.id],
    preferred_countries: currCustomer?.preferred_countries
      ? currCustomer?.preferred_countries?.map((country) =>
          typeof country === "number" ? country : Number(country?.country_id)
        )
      : [],
    courses: currCustomer?.courses
      ? filterCourses(currCustomer.courses)
      : [
          {
            course_id: null,
            overall: null,
            listening: null,
            reading: null,
            writing: null,
            speaking: null,
            literacy: null,
            comprehension: null,
            conversation: null,
            production: null,
          },
        ],
    jobs: currCustomer?.jobs ? filterJobs(currCustomer.jobs) : [],
    country_id: currCustomer?.country_id ? currCustomer.country_id : null,
    description: currCustomer?.description ? currCustomer.description : "",
    address: currCustomer?.address ? currCustomer.address : "",
    city: currCustomer?.city ? currCustomer.city : "",
    state: currCustomer?.state ? currCustomer.state : "",
    contacted_date: currCustomer?.contacted_date
      ? currCustomer?.contacted_date
      : null,
    status: currCustomer?.status ? currCustomer.status : "Active",
    previous_refused: currCustomer?.previous_refused
      ? currCustomer?.previous_refused
      : "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(
      currCustomer?.email,
      currCustomer?.phone
    ),
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer: handleClose,
        width: "850px",
        headingLeft: isUpdating ? "Edit Customer" : "New Customer",
        headingRight: "",
      }}
    >
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box
          p="20px"
          sx={{
            height: "calc(100vh - 143px)",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
              gap: "15px",
            }}
          >
            <Select
              name="status"
              label="Status *"
              options={["Active", "Inactive"]}
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />

            <Select
              name="source_id"
              label="Source *"
              options={sources}
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />

            <MultiSelect
              name="assignees"
              label="Assignees *"
              options={assignees}
              selectProps={{
                id: "user_id",
                label: "name",
              }}
              formik={formik}
            />
          </Box>
          <Typography
            variant="h5"
            color={(theme) => theme.palette.primary.main}
            borderBottom="1px solid #ddd"
            pb="10px"
            m="35px 0 20px"
          >
            Personal Information
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gap: "15px",
            }}
          >
            <InputField
              name="name"
              label="Name *"
              placeholder="Ex Masud Rana"
              formik={formik}
            />

            <InputField name="phone" label="Phone *" formik={formik} />

            <InputField
              name="email"
              label="E-Mail *"
              placeholder="example@gmail.com"
              formik={formik}
            />

            <InputField
              name="password"
              label={isUpdating ? "Password" : "Password *"}
              placeholder="password"
              formik={formik}
            />

            <Select
              isCountry={true}
              name="country_id"
              label="Country *"
              options={countries}
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />

            <InputField name="city" label="City" formik={formik} />

            <InputField
              name="address"
              label="Address"
              placeholder="Sector-10, Uttara"
              formik={formik}
            />
            <DateTimePicker
              name="contacted_date"
              label="Date of contact"
              type="date" // time
              formik={formik}
            />

            <InputField
              name="previous_refused"
              label="Previous Refuse *"
              formik={formik}
              multiline
              rows={3}
              sx={{ gridColumn: "1 / -1" }}
            />
            <InputField
              name="description"
              label="Aditional Informations"
              formik={formik}
              multiline
              rows={3}
              sx={{ gridColumn: "1 / -1" }}
            />
          </Box>
          <EduFields
            formik={formik}
            subjects={subjects}
            institutes={institutes}
            universities={universities}
          />
          <Box
            sx={{
              gridColumn: "1 / -1",
              borderBottom: "1px solid #ddd",
              pb: "10px",
              m: "35px 0 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              color={(theme) => theme.palette.primary.main}
            >
              English language Proficiency *
            </Typography>

            {/* Add course button */}
            <Button
              startIcon={<Add />}
              onClick={() =>
                formik.setFieldValue("courses", [
                  {
                    course_id: null,
                    overall: null,
                    listening: null,
                    reading: null,
                    writing: null,
                    speaking: null,
                    literacy: null,
                    comprehension: null,
                    conversation: null,
                    production: null,
                  },
                  ...formik.values.courses,
                ])
              }
            >
              {formik.values?.courses?.length
                ? "Add another course"
                : "Add course"}
            </Button>
          </Box>

          {/* courses fields */}
          {formik.values?.courses?.length > 0 &&
            formik.values?.courses.map((singleCourse, i) => (
              <ELPCForms
                singleCourse={singleCourse}
                formik={formik}
                i={i}
                courses={courses}
              ></ELPCForms>
            ))}

          <Box
            sx={{
              gridColumn: "span 2",
              borderBottom: "1px solid #ddd",
              pb: "10px",
              m: "35px 0 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              color={(theme) => theme.palette.primary.main}
            >
              Job Exprience
            </Typography>
            <Button
              startIcon={<Add />}
              onClick={() => {
                formik.setFieldValue("jobs", [
                  ...formik.values.jobs,
                  {
                    company_address: null,
                    company_name: null,
                    duration: null,
                    end_date: null,
                    join_date: null,
                    title: null,
                  },
                ]);
              }}
            >
              {formik.values?.jobs?.length ? "Add another job" : "Add job"}
            </Button>
          </Box>
          {formik.values.jobs?.length > 0 &&
            formik.values?.jobs.map((singleJob, i) => (
              <JobFroms singleJob={singleJob} formik={formik} i={i}></JobFroms>
            ))}
          <Typography
            variant="h5"
            color={(theme) => theme.palette.primary.main}
            borderBottom="1px solid #ddd"
            pb="10px"
            m="35px 0 20px"
          >
            Preferred Countries
          </Typography>
          <MultiSelect
            options={countries}
            label="Select countries"
            value={formik?.values?.preferred_countries}
            onChange={(value) =>
              formik.setFieldValue("preferred_countries", value)
            }
            selectProps={{
              id: "id",
              label: "name",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          padding: "20px",
        }}
      >
        <Button
          variant="solid"
          onClick={formik.submitForm}
          fullWidth
          disabled={!(formik.dirty && formik.isValid) || loading}
        >
          {isUpdating ? "Update Customer" : "Save Customer"}
        </Button>
      </Box>
    </RightDrawer>
  );
};

export default AddEdit;
