import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Alert from "components/alert";
import TableSkeleton from "components/skeletons/TableSkeleton";
import { deleteMakePayment } from "features/invoices/bank/api/invoiceBank";
import useFormatter from "hooks/useFormatter";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";

const MakePaymentTable = ({
  loading,
  allPayment,
  setAddPayment,
  setCurrPayment,
  setAllPayment,
  symbol,
}) => {
  const { formatDate, formatCurrency } = useFormatter();
  const rows = allPayment;
  const columns = [
    {
      id: "payment_method",
      label: "Payment Method",
    },
    {
      id: "total_paid",
      label: "Total Paid",
    },
    { id: "payment_date", label: "Payment Date" },
    {
      id: "action",
      label: "Actions",
      style: { width: "130px" },
      align: "center",
    },
  ];

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = async (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    const result = await deleteMakePayment(openAlert);
    if (result.success) {
      toast.success(result.message);
      setAllPayment(allPayment.filter((task) => task.id !== openAlert));
    } else {
      toast.error(result.message);
    }
    setOpenAlert(false);
  };

  const handleEditBtn = (value) => {
    setCurrPayment(value);
    setAddPayment(true);
  };

  return (
    <Fragment>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Payment"
          desc={
            <Box>
              Are you sure you want to delete? This will delete this payment
              permanently. <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      {!loading ? (
        <>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 256px)",
              overflowX: "auto",
              ml: "-20px",
              mr: "-20px",
              width: "calc(100% + 40px)",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row) => {
                  return (
                    <TableRow hover role="checkbox" key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "total_paid" ? (
                              <>{symbol + formatCurrency(value)}</>
                            ) : column.id === "payment_date" ? (
                              formatDate(value)
                            ) : column.id === "action" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "10px",
                                  justifyContent: "center",
                                }}
                              >
                                <IconButton onClick={() => handleEditBtn(row)}>
                                  <Edit />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleDelete(row.id)}
                                >
                                  <Delete />
                                </IconButton>
                              </Box>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Total amount count */}
          <Box
            sx={{
              mt: "10px",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography sx={{ fontWeight: "500" }} align="right">
              Total Paid:
            </Typography>
            <Typography sx={{ fontWeight: "500" }} align="right">
              {symbol}
              {formatCurrency(
                rows.reduce((acc, curr) => acc + curr.total_paid, 0)
              )}
            </Typography>
          </Box>
        </>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 265px)" />
      )}
    </Fragment>
  );
};

export default MakePaymentTable;
