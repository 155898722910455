import { BackupOutlined, Delete, Download } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Alert from "components/alert";
import NotAuthorized from "components/notAuthorized";
import TableSkeleton from "components/skeletons/TableSkeleton";
import useApi from "hooks/useApi";
import usePermissions from "hooks/usePermissions";
import moment from "moment";
import { useEffect, useState } from "react";

const Backup = () => {
  const { loading, fetchData } = useApi();
  const [allBackup, setAllBackup] = useState([]);
  const permissions = usePermissions("database-backups");

  useEffect(() => {
    const getBackupList = async () => {
      const endpoint = {
        method: "get",
        url: "/api/admin/database-backups/list",
      };
      const result = await fetchData(endpoint, false);
      if (result.success) setAllBackup(result?.data?.reverse());
    };
    getBackupList();
  }, []);

  const handleGenerateBackup = async () => {
    const endpoint = {
      method: "post",
      url: "/api/admin/database-backups",
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      setAllBackup(result?.data?.reverse());
    }
  };

  const [openAlert, setOpenAlert] = useState(false);

  const handleDeleteBackup = async (name) => {
    setOpenAlert(name);
  };

  const handleDeleteConfirm = async () => {
    const endpoint = {
      method: "delete",
      url: `/api/admin/database-backups/delete/${openAlert}`,
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      setAllBackup(result?.data?.reverse());
    }
    setOpenAlert(false);
  };

  const columns = [
    { id: "created_at", label: "Date" },
    { id: "name", label: "File Name" },
    { id: "size", label: "Size" },
    {
      id: "action",
      label: "Actions",
      style: { width: "150px" },
      align: "center",
    },
  ];

  return (
    <Box>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Backup"
          desc={
            <Box>
              Are you sure you want to delete? This will delete this backup
              permanently. <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        p="15px 20px"
      >
        <Box></Box>
        <Button
          variant="outlined"
          startIcon={<BackupOutlined />}
          onClick={handleGenerateBackup}
          disabled={!permissions["database backups"]}
        >
          Generate New Backup
        </Button>
      </Grid>
      {permissions["database list"] ? (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          {!loading ? (
            <TableContainer
              sx={{ height: "calc(100vh - 133px)", overflowX: "auto" }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow
                    sx={{
                      "& .MuiTableCell-sizeMedium": {
                        padding: "10px 0px 10px 20px",
                      },
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={column.style}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allBackup?.map((row) => {
                    return (
                      <TableRow
                        sx={{
                          "& .MuiTableCell-sizeMedium": {
                            padding: "10px 0px 10px 20px",
                          },
                        }}
                        hover
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={column.style}
                            >
                              {column.id === "created_at" ? (
                                moment(value).format("lll")
                              ) : column.id === "action" ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "15px",
                                    justifyContent: "center",
                                  }}
                                >
                                  <a
                                    href={row?.download_link}
                                    target="_blank"
                                    rel="noreferrer"
                                    download={row?.name}
                                  >
                                    <Tooltip title="Download">
                                      <IconButton>
                                        <Download />
                                      </IconButton>
                                    </Tooltip>
                                  </a>

                                  <Tooltip title="Delete">
                                    <IconButton
                                      onClick={() =>
                                        handleDeleteBackup(row?.name)
                                      }
                                      disabled={!permissions["database delete"]}
                                    >
                                      <Delete style={{ fontSize: "22px" }} />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableSkeleton
              columns={columns}
              tableHeight="calc(100vh - 133px)"
            />
          )}
        </Paper>
      ) : (
        <NotAuthorized />
      )}
    </Box>
  );
};

export default Backup;
