import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PopoverAG from "components/ui/popoverAG";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import useFormatter from "hooks/useFormatter";
import useQueries from "hooks/useQueries";
import { TbListDetails } from "react-icons/tb";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import handleError from "utils/handleError";

export const taskpriorities = [
  { id: 1, label: "Low", color: "#008000" },
  { id: 2, label: "Medium", color: "#FFA500" },
  { id: 3, label: "High", color: "#FF0000" },
  { id: 4, label: "Urgent", color: "#800080" },
];

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  permissions,
  allTask,
  setAllTask,
  ...restProps
}) => {
  const { setQueries } = useQueries();
  const { formatDate } = useFormatter();
  const { base } = useBase();
  const { taskStatuses } = base;
  const user_id = useSelector((state) => state?.auth.user_details.user.id);

  const { fetchData } = useApi();

  const getPriorityDetails = (value) =>
    taskpriorities?.find((p) => p.id === Number(value));

  const handleChangeStatus = async (id) => {
    const result = await fetchData({
      method: "post",
      url: "/api/admin/task/status",
      data: {
        task_id: row.id,
        task_status_id: id,
      },
    });
    const { data, success } = result;
    if (success) {
      setAllTask(
        allTask?.map((task) =>
          task.id === row.id ? { ...row, ...data } : task
        )
      );
    } else {
      const errors = handleError(result);
      errors?.map((err) => toast.error(err));
    }
  };

  return (
    <TableRow
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 0px 10px 20px",
        },
      }}
      {...restProps}
      hover
      key={row.id}
    >
      {columns.map((column, i) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align}>
            {column.id === "status" ? (
              <TextField
                disabled={!permissions["status change"]}
                size="small"
                value={row?.task_status_id}
                select
                sx={{
                  width: "130px",
                  color: row?.status_color,
                  textAlign: "left",
                  "& > div:before": { content: "none" },
                  "& div": { color: row?.status_color },
                }}
                variant="standard"
                onChange={(e) => handleChangeStatus(e.target.value)}
              >
                {taskStatuses?.length > 0 &&
                  taskStatuses?.map((status) => (
                    <MenuItem value={status?.id} key={status?.id}>
                      {status?.name}
                    </MenuItem>
                  ))}
              </TextField>
            ) : column.id === "related_to" ? (
              <Typography variant="body2" textTransform="capitalize">
                {value || "-"}
              </Typography>
            ) : column.id === "date_added" ||
              column.id === "due_date" ||
              column.id === "date_finished" ? (
              formatDate(value)
            ) : column.id === "assignees" || column.id === "followers" ? (
              <PopoverAG items={value} />
            ) : column.id === "priority" ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  sx={{
                    backgroundColor: `${getPriorityDetails(value)?.color}15`,
                    color: getPriorityDetails(value)?.color,
                    textAlign: "center",
                    padding: "3px 5px",
                    borderRadius: "20px",
                    width: "80px",
                    mt: "5px",
                    fontSize: "13px",
                  }}
                >
                  {getPriorityDetails(value)?.label}
                </Box>
              </Box>
            ) : column.id === "action" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Details">
                  <IconButton onClick={() => setQueries(`id=${row?.id}&m=1`)}>
                    <TbListDetails />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => setQueries(`id=${row?.id}&m=0`)}
                    disabled={
                      (row.created_by !== user_id && !permissions.isAdmin) ||
                      !permissions.update
                    }
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    disabled={!permissions.delete}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              value || "-"
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
