import { TabList, TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import { Box, Tab } from "@mui/material";
import Activity from "components/common/activity";
import Attachment from "components/common/attachment";
import Note from "components/common/note";
import Task from "components/common/task";
import NotAuthorized from "components/notAuthorized";
import RightDrawer from "components/rightDrawer/RightDrawer";
import usePermissions from "hooks/usePermissions";
import { useState } from "react";
import Payment from "./payment/Payment";
import Profile from "./profile/Profile";
import useQueries from "hooks/useQueries";
import useApi from "hooks/useApi";
import { useEffect } from "react";

const ViewInvoice = ({
  openDrawer,
  setOpenDrawer,
  allInvoices,
  setAllInvoices,
  permissions,
}) => {
  const { row_id, mod_id, setQueries } = useQueries();
  const { fetchData, loading } = useApi();
  const [profile, setProfile] = useState({});

  const handleChange = (event, newValue) => {
    setQueries(`id=${row_id}&m=${newValue}`);
  };

  useEffect(() => {
    const getProfile = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/invoice/show/${row_id}`,
      };
      const { success, data } = await fetchData(endpoint, false);
      if (success) setProfile(data);
    };
    getProfile();
  }, []);

  const payPermissions = usePermissions("payment");

  return (
    <RightDrawer
      {...{
        nativeBack: true,
        openDrawer,
        setOpenDrawer,
        width: "900px",
        headingLeft:
          "#" +
          row_id +
          `${profile?.invoice_number ? ` - ${profile?.invoice_number}` : ""}`,
        headingRight: "",
      }}
    >
      <Box sx={{ width: "100%", typography: "body1", overflowX: "hidden" }}>
        <TabContext value={mod_id || "1"}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="View Invoice" value="1" />
              <Tab label="Attachment" value="2" />
              <Tab label="Note" value="3" />
              <Tab label="Task" value="4" />
              <Tab label="Payments" value="5" />
              <Tab label="Activity" value="6" />
            </TabList>
          </Box>
          <Box
            sx={{
              position: "relative",
              height: "calc(100vh - 114px)",
            }}
          >
            <TabPanel value="1" sx={{ p: 0 }}>
              {permissions["show"] ? (
                <Profile profile={profile} loading={loading} />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="2">
              {permissions["attachment"] ? (
                <Attachment id={row_id} route="invoice" />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="3">
              {permissions["note"] ? (
                <Note
                  id={row_id}
                  related_id={row_id}
                  related_to="invoice"
                  table_note_key="notes"
                  route="invoice"
                  allData={allInvoices}
                  setAllData={setAllInvoices}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel sx={{ paddingBottom: "0px" }} value="4">
              {permissions["task"] ? (
                <Task id={row_id} route="invoice" related_to="invoice" />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="5">
              {payPermissions["create"] ? (
                <Payment id={row_id} currency_id={profile?.currency_id} />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="6">
              {permissions["activity"] ? (
                <Activity
                  activityList={{
                    method: "get",
                    url: `/api/admin/invoice/activity/${row_id}`,
                  }}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </RightDrawer>
  );
};

export default ViewInvoice;
