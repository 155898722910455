import { Notifications as NotificationsIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Avatar, Badge, Box, IconButton, Menu, MenuItem } from "@mui/material";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import defaultNotficationSound from "assets/audio/ding.mp3";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import useTimeAgo from "hooks/useTimeAgo";
import useWindowSize from "hooks/useWindowSize";
import Axios from "lib/Axios";
import Pusher from "pusher-js";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

let redirectNotificationPath = [
  {
    module_name: "Lead",
    path: "/leads",
  },

  {
    module_name: "Customer",
    path: "/customers",
  },
  {
    module_name: "Project",
    path: "/projects",
  },
  {
    module_name: "Invoice",
    path: "/revenue-records/invoices",
  },
  {
    module_name: "Expense",
    path: "/expenses",
  },
  {
    module_name: "Payment",
    path: "/payments",
  },
  {
    module_name: "Task",
    path: "/tasks",
  },
  {
    module_name: "Ticket",
    path: "/tickets",
  },

  {
    module_name: "Knowledge",
    path: "/support/knowledge",
  },

  {
    module_name: "Trash-Lead",
    path: "/trash/leads",
  },

  {
    module_name: "Trash-Project",
    path: "/trash/projects",
  },

  {
    module_name: "Trash-Customer",
    path: "/trash/customers",
  },

  {
    module_name: "Trash-Invoice",
    path: "/trash/invoices",
  },
  {
    module_name: "Trash-Expense",
    path: "/trash/expenses",
  },
];

let intPage = {
  page: 1,
  current_page: 1,
  last_page: null,
  total: 0,
};
const fetchNotiApi = ({ page, body_data }) =>
  Axios.post(
    `/api/admin/notification/list?page=${page}&limit=10`,
    body_data
  ).then((res) => res.data);

const Notification = () => {
  const { isMini } = useWindowSize();
  let user_id = useSelector((state) => state.auth.user_details.user.id);
  const settings = useSelector((state) => state?.base?.base_data?.settings);
  const divScrollRef = useRef(null);
  const [notification, setNotification] = useState([]);
  const [countUnread, setCountUnread] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(intPage);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let { loading: loadingAllReadNotifiApi, fetchData: readAllNotifiApi } =
    useApi();

  let handleReadAll = async () => {
    const endpoint = {
      method: "post",
      url: `/api/admin/notification/read/all`,
      data: {
        user_id,
      },
    };
    setNotification((prev) =>
      prev.map((item) => (!item.read_at ? { ...item, read_at: true } : item))
    );
    const result = await readAllNotifiApi(endpoint, false);

    if (result.code !== 200) {
      setNotification((prev) =>
        prev.map((item) => (item.read_at ? { ...item, read_at: false } : item))
      );
    } else {
      setCountUnread(0);
    }
  };

  let fetchInifinteScoll = async (newNotifiMsg, scroll) => {
    const result = await fetchNotiApi({
      page: page?.page,
      body_data: {
        user_id: [user_id],
      },
    });
    let { data, ...rest } = result?.data?.data;
    if (result.success) {
      if (scroll) {
        setNotification((prev) => [...prev, ...data]);
      } else {
        setCountUnread(result.data.unread_at_count);
        setNotification(result?.data?.data.data);
        newNotifiMsg && toast.success(newNotifiMsg);
      }
      setPage((prevPage) => ({ page: prevPage.page + 1, ...rest }));
    }
  };

  useEffect(() => {
    fetchInifinteScoll("", false);
  }, []);

  useEffect(() => {
    const pusher = new Pusher(settings?.notification?.pusher_key || "", {
      cluster: settings?.notification?.pusher_cluster || "",
      useTLS: false,
      forceTLS: false,
    });
    try {
      const channel = pusher.subscribe("notification");
      channel.bind("notification", function (data) {
        let findNotificator = data?.data?.includes(user_id);
        if (findNotificator) {
          fetchInifinteScoll(data?.message, false);
          setPage(intPage);
          audioRef.current.muted = false;
          audioRef.current?.play().catch((e) => {});

          if (divScrollRef.current) {
            divScrollRef.current.scrollTop = 0;
          }
        }
      });
    } catch (error) {
    }
    return () => {
      pusher.unsubscribe("notification");
    };
  }, []);

  const audioRef = useRef(null);
  useEffect(() => {
    const handleClick = () => {
      audioRef.current.muted = true;
      if (audioRef.current.paused) {
        audioRef.current?.play().catch((e) => {});
      }
    };
    document.addEventListener("click", handleClick, { once: true });
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <>
      {/* ================= Notification Sound ==========*/}
      <audio ref={audioRef}>
        <source
          src={
            settings?.notification?.notification_sound
              ? UNIVERSAL.BASEURL + settings?.notification?.notification_sound
              : defaultNotficationSound
          }
          type="audio/mpeg"
        />
      </audio>
      <Tooltip title="Notifications">
        <Badge
          badgeContent={countUnread}
          max={99}
          color="red"
          sx={{
            color: "#fff",
            fontWeight: "500",
          }}
          className="ntf-badge"
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
          >
            <NotificationsIcon color="primary" />
          </IconButton>
        </Badge>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: "drop-shadow(0px 5px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            ml: "-4px",
            width: "100%",
            maxWidth: isMini ? "350px" : "450px",
            height: "calc(100vh - 70px)",
          },
        }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px ",
            justifyContent: "space-between",
            p: "5px 15px 10px 20px",
          }}
        >
          <Typography variant="h4">Notifications</Typography>
          <LoadingButton
            variant="text"
            size="small"
            onClick={handleReadAll}
            disabled={
              (notification.length > 0 &&
                !notification.some((item) => !item.read_at)) ||
              loadingAllReadNotifiApi
            }
          >
            Mark All as Read
          </LoadingButton>
        </Box>
        <Box
          sx={{
            height: "calc(100vh - 165px)",
            overflow: "auto",
          }}
          id="scrollableDiv"
          ref={divScrollRef}
          className="disable_bar"
        >
          <InfiniteScroll
            dataLength={notification.length}
            next={() => fetchInifinteScoll("", true)}
            hasMore={page?.current_page === page?.last_page ? false : true}
            loader={
              !!notification.length && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "40px",
                  }}
                >
                  <ReactLoading
                    type="spin"
                    color="#000"
                    height="23px"
                    width="23px"
                  />
                </Box>
              )
            }
            scrollableTarget="scrollableDiv"
          >
            {notification?.map((item, index) => (
              <SingleNotification
                key={item?.id}
                item={item}
                index={index}
                setNotification={setNotification}
                user_id={user_id}
                handleClose={handleClose}
                setCountUnread={setCountUnread}
                countUnread={countUnread}
              />
            ))}
          </InfiniteScroll>
        </Box>
      </Menu>
    </>
  );
};

export default Notification;

const SingleNotification = ({
  item,
  index,
  setNotification,
  user_id,
  handleClose,
  setCountUnread,
  countUnread,
}) => {
  const navigate = useNavigate();
  const formatTimeAgo = useTimeAgo("full");

  let { fetchData: readNotifiApi } = useApi();
  let handleRead = async (e, id) => {
    e.stopPropagation();
    const endpoint = {
      method: "post",
      url: `/api/admin/notification/read`,
      data: { user_id, id },
    };
    const result = await readNotifiApi(endpoint, false);
    if (result.success) {
      setNotification((prev) =>
        prev.map((item) =>
          item?.id === id ? { ...item, read_at: true } : item
        )
      );
      countUnread > 0 && setCountUnread((prev) => prev - 1);
    }
  };

  return (
    <>
      {index !== 0 && (
        <Divider
          sx={{
            m: "0 !important",
            borderColor: "#efefef !important",
          }}
        />
      )}
      <MenuItem
        disableRipple
        onClick={(e) => {
          if (!item?.read_at) {
            handleRead(e, item?.id);
          }
          let path = redirectNotificationPath.find(
            (c) =>
              c.module_name.toLowerCase() ===
              item?.data?.notification?.module_name.toLowerCase()
          ).path;
          if (!item?.data?.message?.includes("trash"))
            path += `?id=${item?.data?.notification?.data?.id}&m=1`;
          if (path) navigate(path);
          handleClose();
        }}
        sx={{
          ...(!item?.read_at && {
            background: "#FFF9F9",
          }),
          pointerEvents: item.data.message.includes("deleted") ? "none" : "all",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "35px auto",
            gap: "13px",
            pt: "10px",
          }}
        >
          <Box sx={{ pt: "3px" }}>
            <Avatar
              alt={item?.data?.notificationCreator?.name}
              src={UNIVERSAL?.BASEURL + item?.data?.notificationCreator?.image}
              sx={{ width: 35, height: 35, border: "none" }}
            ></Avatar>
          </Box>
          <Box
            sx={{
              pb: "10px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                whiteSpace: "normal",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              <Typography
                variant="span"
                sx={{
                  whiteSpace: "500",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                {item?.data?.notificationCreator?.name}
              </Typography>{" "}
              {item.data.message}
            </Typography>

            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              {formatTimeAgo(item?.created_at).value}{" "}
              {formatTimeAgo(item?.created_at).isNew ? (
                <span
                  style={{
                    fontSize: "12px",
                    color: "green",
                  }}
                >
                  New
                </span>
              ) : (
                ""
              )}
            </Typography>
          </Box>
        </Box>
      </MenuItem>
    </>
  );
};
