import { Add } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import InputField from "components/ui/inputField";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import moment from "moment";
import { memo, useRef } from "react";
import { toast } from "react-toastify";
import handleError from "utils/handleError";
import validationSchema from "../schema";
import ELPCForms from "./ELPCForms";
import EducationForms from "./EducationForms";
import JobFroms from "./JobFroms";

const currentDate = moment().format("YYYY-MM-DD");

const Form = ({ source, dropdownData }) => {
  const { countries, universities, courses, institutes, subjects } =
    dropdownData || {};
  const errorFieldRef = useRef(null);

  const { loading, fetchData } = useApi();
  const onSubmit = async (data, action) => {
    const endpoint = {
      method: "post",
      url: `/api/web-form`,
      data,
    };
    const result = await fetchData(endpoint, false);
    if (result?.success) {
      toast.success("Form Submited successfully");
      action.resetForm();
    } else {
      handleError(result, formik);
      gotoOnErrorField(result.errors);
    }
  };
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    source,
    preferred_countries: [],
    country_id:
      countries?.find((item) => item?.short_name === "BD")?.id || null,
    description: "",
    address: "",
    city: "",
    state: "",
    contacted_date: currentDate,
    // Educations
    educations: [],
    // SSC
    ssc_group: null,
    ssc_gpa: null,
    ssc_passing_year: null,
    ssc_board: null,
    // HSC
    hsc_group: null,
    hsc_gpa: null,
    hsc_passing_year: null,
    hsc_board: null,
    // Bachelor
    bachelor_subject_id: null,
    bachelor_gpa: null,
    bachelor_passing_year: null,
    bachelor_university_id: null,
    // Master
    master_subject_id: null,
    master_gpa: null,
    master_passing_year: null,
    master_university_id: null,

    // Diploma
    diploma_subject_id: null,
    diploma_group: null,
    diploma_gpa: null,
    diploma_passing_year: null,
    diploma_institute_id: null,
    diploma_board: null,

    // O level
    o_level_grade: null,
    o_level_passing_year: null,
    o_level_institute_id: null,
    // O level
    a_level_grade: null,
    a_level_passing_year: null,
    a_level_institute_id: null,
    //others
    courses: [],
    jobs: [],
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const gotoOnErrorField = (errors) => {
    if (errorFieldRef.current && Object.keys(errors).length) {
      errorFieldRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Box
      sx={{ scrollMarginTop: "2rem", position: "relative" }}
      ref={errorFieldRef}
    >
      <form onSubmit={formik.handleSubmit}>
        <Typography
          variant="h5"
          color={(theme) => theme.palette.primary.main}
          m="0 0 10px"
        >
          Personal Information
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gap: "15px",
          }}
        >
          <InputField name="name" label="Name *" formik={formik} />
          <InputField
            fullWidth
            id="phone"
            name="phone"
            label="Phone *"
            formik={formik}
          />
          <InputField
            name="email"
            label="E-Mail"
            placeholder="example@gmail.com"
            formik={formik}
          />

          <Select
            isCountry={true}
            options={countries}
            label="Country"
            value={formik?.values?.country_id}
            onChange={(value) => formik.setFieldValue("country_id", value)}
            selectProps={{ id: "id", label: "name" }}
          />
          <InputField name="city" label="City" formik={formik} />
          <InputField
            name="address"
            label="Address"
            placeholder="Sector-10, Uttara"
            formik={formik}
          />

          <InputField
            name="description"
            label="Aditional Informations"
            formik={formik}
            multiline
            rows={3}
            sx={{ gridColumn: "1 / -1" }}
          />
        </Box>
        <Typography
          variant="h5"
          color={(theme) => theme.palette.primary.main}
          m="35px 0 10px"
        >
          Educational Information
        </Typography>
        <EducationForms
          {...{
            formik,
            subjects,
            institutes,
            universities,
          }}
        />

        <Box
          sx={{
            gridColumn: "1 / -1",
            borderBottom: "1px solid #ddd",
            pb: "10px",
            m: "35px 0 20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            color={(theme) => theme.palette.primary.main}
          >
            English language Proficiency
          </Typography>
          <Button
            startIcon={<Add />}
            onClick={() =>
              formik.setFieldValue("courses", [
                {
                  course_id: null,
                  overall: null,
                  listening: null,
                  reading: null,
                  writing: null,
                  speaking: null,
                  literacy: null,
                  comprehension: null,
                  conversation: null,
                  production: null,
                },
                ...formik.values.courses,
              ])
            }
          >
            {formik.values?.courses?.length
              ? "Add another course"
              : "Add course"}
          </Button>
        </Box>
        {formik.values?.courses?.length > 0 &&
          formik.values?.courses.map((singleCourse, i) => (
            <ELPCForms
              singleCourse={singleCourse}
              formik={formik}
              i={i}
              courses={courses}
            ></ELPCForms>
          ))}

        <Box
          sx={{
            gridColumn: "span 2",
            borderBottom: "1px solid #ddd",
            pb: "10px",
            m: "35px 0 20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            color={(theme) => theme.palette.primary.main}
          >
            Job Exprience
          </Typography>
          <Button
            startIcon={<Add />}
            onClick={() => {
              formik.setFieldValue("jobs", [
                ...formik.values.jobs,
                {
                  company_address: null,
                  company_name: null,
                  duration: null,
                  end_date: null,
                  join_date: null,
                  title: null,
                },
              ]);
            }}
          >
            {formik.values?.jobs?.length ? "Add another job" : "Add job"}
          </Button>
        </Box>
        {formik.values.jobs?.length > 0 &&
          formik.values?.jobs.map((singleJob, i) => (
            <JobFroms singleJob={singleJob} formik={formik} i={i}></JobFroms>
          ))}
        <Typography
          variant="h5"
          color={(theme) => theme.palette.primary.main}
          m="35px 0 10px"
        >
          Preferred Countries
        </Typography>
        <MultiSelect
          options={countries}
          label="Select countries"
          value={formik?.values?.preferred_countries}
          onChange={(value) =>
            formik.setFieldValue("preferred_countries", value)
          }
          selectProps={{
            id: "id",
            label: "name",
          }}
        />

        <Box
          sx={{
            position: "sticky",
            zIndex: 999,
            background: "#fff",
            width: "100%",
            right: 0,
            left: 0,
            bottom: 0,
            py: 2,
          }}
        >
          <Button
            variant="solid"
            type="submit"
            fullWidth
            disabled={!(formik.dirty && formik.isValid) || loading}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default memo(Form);
