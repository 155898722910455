import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import moment from "moment";

const PaymentTable = ({
  loading,
  allPayment,
  pagination,
  setPagination,
  setReload,
}) => {
  const rows = allPayment;
  const columns = [
    { id: "invoice_number", label: "Invoice Number" },
    { id: "created_at", label: "Created Date" },
    { id: "created_by_name", label: "Created By" },
    { id: "customer_name", label: "Customer Name" },
    {
      id: "payment_method_name",
      label: "Pay Method",
      style: { width: "120px" },
    },
    { id: "payment_date", label: "Payment Date", style: { width: "130px" } },
    { id: "amount", label: "Amount", align: "right" },
  ];

  return (
    <>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 245px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : column.id === "Payment_date" ? (
                            moment(value).format("ll")
                          ) : column.id === "created_at" ? (
                            moment(value).format("ll")
                          ) : column.id === "tax" ? (
                            <Typography color="#2fb42f" variant="body2">
                              {"+ " + row.symbol + value}
                            </Typography>
                          ) : column.id === "discount" ? (
                            <Typography color="#ff3535" variant="body2">
                              {"- " + row.symbol + value}
                            </Typography>
                          ) : column.id === "total" ? (
                            <Typography color="#7171ff" variant="body2">
                              {row.symbol}
                              {value + row.tax - row.discount}
                            </Typography>
                          ) : column.id === "subtotal" ? (
                            <Typography variant="body2">
                              {row.symbol + value}
                            </Typography>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 245px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

export default PaymentTable;
