import RightDrawer from "components/rightDrawer/RightDrawer";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import {
  addCustomerToProject,
  updateProjectCustomer,
} from "features/projectCustomers/api/projectCustomer";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import ReactLoading from "react-loading";
import validationSchema from "../../schema";
// MUI COMPONENTS
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import CheckDateBeforeToday from "features/projectCustomers/utils/CheckDateBeforeToday";
import { useSelector } from "react-redux";
import handleError from "utils/handleError";
import useQueries from "hooks/useQueries";
import { useState } from "react";
import { useEffect } from "react";

const AddEdit = ({
  persistedData,
  setPersistedData,
  openDrawer,
  setOpenDrawer,
  references,
  setAllCustomers,
  allCustomers,
  projects,
  assignees,
  customers,
  projectCustomerStatuses,
  intakes,
  branches,
}) => {
  const { row_id } = useQueries();
  const { loading, fetchData } = useApi();
  const [currCustomer, setCurrCustomer] = useState(row_id ? {} : persistedData);
  const isUpdating = row_id ? true : false;
  const user_id = useSelector((state) => state?.auth?.user_details.user.id);

  const handleClose = (reset) => {
    setPersistedData(row_id ? {} : reset ? {} : formik?.values);
    setOpenDrawer();
  };

  useEffect(() => {
    const getFullProjectCustomer = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/project-customer/show/${row_id}`,
      };
      const result = await fetchData(endpoint, false);
      result.success && setCurrCustomer(result?.data);
    };
    row_id && getFullProjectCustomer();
  }, []);

  const onSubmit = async (data) => {
    if (row_id) {
      const result = await fetchData(
        updateProjectCustomer(currCustomer?.id, data)
      );
      if (result.success) {
        setAllCustomers(
          allCustomers.map((customer) =>
            customer.id === currCustomer.id
              ? { ...currCustomer, ...result.data }
              : customer
          )
        );
        handleClose("reset");
      } else {
        handleError(result, formik);
      }
    } else {
      const result = await fetchData(addCustomerToProject(data));
      if (result.success) {
        setAllCustomers([{ ...result.data, notes: [] }, ...allCustomers]);
        handleClose("reset");
      } else {
        handleError(result, formik);
      }
    }
  };

  const initialValues = {
    project_id: currCustomer?.project_id || "",
    customer_id: currCustomer?.customer_id || "",
    project_customer_status_id: currCustomer?.project_customer_status_id || "",
    active: currCustomer?.active || "",
    intake_id: currCustomer?.intake_id || "",
    assignees: currCustomer?.id
      ? currCustomer?.assignees?.map((assignee) => assignee?.user_id)
      : [user_id],
    reference_type: currCustomer?.reference_type || "",
    reference_id: currCustomer?.reference_id || "",
    start_date: currCustomer?.start_date || null,
    end_date: currCustomer?.end_date || null,
    description: currCustomer?.description || "",
    branch_id: currCustomer?.branch_id || "",

    academic_doc: currCustomer?.academic_doc || 0,
    academic_doc_receive_date: currCustomer?.academic_doc_receive_date || null,

    passport: currCustomer?.passport || 0,
    passport_receive_date: currCustomer?.passport_receive_date || null,

    cv: currCustomer?.cv || 0,
    cv_receive_date: currCustomer?.cv_receive_date || null,

    photo: currCustomer?.photo || 0,
    photo_receive_date: currCustomer?.photo_receive_date || null,

    medical_certificate: currCustomer?.medical_certificate || 0,
    medical_certificate_receive_date:
      currCustomer?.medical_certificate_receive_date || null,

    police_clearance: currCustomer?.police_clearance || 0,
    police_clearance_receive_date:
      currCustomer?.police_clearance_receive_date || null,

    job_doc: currCustomer?.job_doc || 0,
    job_doc_receive_date: currCustomer?.job_doc_receive_date || null,

    tax_doc: currCustomer?.tax_doc || 0,
    tax_doc_receive_date: currCustomer?.tax_doc_receive_date || null,
    elp_doc: currCustomer?.elp_doc || 0,
    elp_doc_receive_date: currCustomer?.elp_doc_receive_date || null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer: handleClose,
        width: "850px",
        headingLeft: isUpdating
          ? "Edit Project Customer"
          : "Add Customer To Project",
        headingRight: "",
      }}
    >
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box
          p="20px"
          sx={{
            height: "calc(100vh - 143px)",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "15px",
            }}
          >
            <Select
              name="branch_id"
              formik={formik}
              options={branches}
              label="Branch"
              selectProps={{ id: "id", label: "name" }}
            />
            <Select
              options={customers}
              label="Customer *"
              selectProps={{ id: "id", label: "name" }}
              name="customer_id"
              formik={formik}
            />
            <Select
              name="project_id"
              formik={formik}
              options={projects}
              label="Project *"
              selectProps={{ id: "id", label: "name" }}
            />
            <Select
              options={projectCustomerStatuses}
              label="Status *"
              name="project_customer_status_id"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />
            <Select
              options={intakes}
              label="Intake *"
              name="intake_id"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />
            <MultiSelect
              name="assignees"
              formik={formik}
              value={formik?.values?.assignees}
              options={assignees}
              label="Assignees *"
              selectProps={{
                id: "user_id",
                label: "name",
              }}
            />
            <Select
              options={references}
              label="Reference"
              name="reference_id"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />
            {formik.values.reference_id && (
              <Select
                options={[
                  { id: "sub", label: "Sub" },
                  { id: "master", label: "Master" },
                ]}
                label="Reference Type"
                name="reference_type"
                formik={formik}
              />
            )}

            <DateTimePicker
              name="start_date"
              label="Start Date"
              formik={formik}
            />
            <DateTimePicker name="end_date" label="End Date" formik={formik} />
            <Select
              formik={formik}
              options={[
                {
                  id: 1,
                  name: "Active",
                },
                {
                  id: 0,
                  name: "Inactive",
                },
              ]}
              label="Project Status"
              name="active"
              selectProps={{
                id: "id",
                label: "name",
              }}
            />
            <InputField
              name="description"
              label="Additional Information"
              formik={formik}
              multiline
              rows={3}
              sx={{ gridColumn: "span 2" }}
            />
            <Stack>
              <FormControlLabel
                control={<Checkbox />}
                label="Academic Documents"
                checked={Number(formik?.values?.academic_doc) ? true : false}
                onChange={(e) => {
                  formik.setFieldValue(
                    "academic_doc",
                    e.target.checked ? 1 : 0
                  );
                }}
              />
              <CheckDateBeforeToday
                givenDate={
                  Number(formik?.values?.academic_doc)
                    ? null
                    : formik?.values?.academic_doc_receive_date
                }
              >
                <DateTimePicker
                  name="academic_doc_receive_date"
                  label={
                    Number(formik?.values?.academic_doc)
                      ? "Receive Date"
                      : "Due Date"
                  }
                  formik={formik}
                />
              </CheckDateBeforeToday>
            </Stack>
            <Stack>
              <FormControlLabel
                control={<Checkbox />}
                label="Passport"
                checked={Number(formik?.values?.passport) ? true : false}
                onChange={(e) => {
                  formik.setFieldValue("passport", e.target.checked ? 1 : 0);
                }}
              />

              <CheckDateBeforeToday
                givenDate={
                  Number(formik?.values?.passport)
                    ? null
                    : formik?.values?.passport_receive_date
                }
              >
                <DateTimePicker
                  name="passport_receive_date"
                  label={
                    Number(formik?.values?.passport)
                      ? "Receive Date"
                      : "Due Date"
                  }
                  formik={formik}
                />
              </CheckDateBeforeToday>
            </Stack>
            <Stack>
              <FormControlLabel
                control={<Checkbox />}
                label="CV"
                checked={Number(formik?.values?.cv) ? true : false}
                onChange={(e) => {
                  formik.setFieldValue("cv", e.target.checked ? 1 : 0);
                }}
              />

              <CheckDateBeforeToday
                givenDate={
                  Number(formik?.values?.cv)
                    ? null
                    : formik?.values?.cv_receive_date
                }
              >
                <DateTimePicker
                  name="cv_receive_date"
                  label={
                    Number(formik?.values?.cv) ? "Receive Date" : "Due Date"
                  }
                  formik={formik}
                />
              </CheckDateBeforeToday>
            </Stack>
            <Stack>
              <FormControlLabel
                control={<Checkbox />}
                label="Photo"
                checked={Number(formik?.values?.photo) ? true : false}
                onChange={(e) => {
                  formik.setFieldValue("photo", e.target.checked ? 1 : 0);
                }}
              />

              <CheckDateBeforeToday
                givenDate={
                  Number(formik?.values?.photo)
                    ? null
                    : formik?.values?.photo_receive_date
                }
              >
                <DateTimePicker
                  name="photo_receive_date"
                  label={
                    Number(formik?.values?.photo) ? "Receive Date" : "Due Date"
                  }
                  formik={formik}
                />
              </CheckDateBeforeToday>
            </Stack>
            <Stack>
              <FormControlLabel
                control={<Checkbox />}
                label="Medical Certificate"
                checked={
                  Number(formik?.values?.medical_certificate) ? true : false
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    "medical_certificate",
                    e.target.checked ? 1 : 0
                  );
                }}
              />

              <CheckDateBeforeToday
                givenDate={
                  Number(formik?.values?.medical_certificate)
                    ? null
                    : formik?.values?.medical_certificate_receive_date
                }
              >
                <DateTimePicker
                  name="medical_certificate_receive_date"
                  label={
                    Number(formik?.values?.medical_certificate)
                      ? "Receive Date"
                      : "Due Date"
                  }
                  formik={formik}
                />
              </CheckDateBeforeToday>
            </Stack>
            <Stack>
              <FormControlLabel
                control={<Checkbox />}
                label="Police Clearance"
                checked={
                  Number(formik?.values?.police_clearance) ? true : false
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    "police_clearance",
                    e.target.checked ? 1 : 0
                  );
                }}
              />

              <CheckDateBeforeToday
                givenDate={
                  Number(formik?.values?.police_clearance)
                    ? null
                    : formik?.values?.police_clearance_receive_date
                }
              >
                <DateTimePicker
                  name="police_clearance_receive_date"
                  label={
                    Number(formik?.values?.police_clearance)
                      ? "Receive Date"
                      : "Due Date"
                  }
                  formik={formik}
                />
              </CheckDateBeforeToday>
            </Stack>
            <Stack>
              <FormControlLabel
                control={<Checkbox />}
                label="Job Document"
                checked={Number(formik?.values?.job_doc) ? true : false}
                onChange={(e) => {
                  formik.setFieldValue("job_doc", e.target.checked ? 1 : 0);
                }}
              />

              <CheckDateBeforeToday
                givenDate={
                  Number(formik?.values?.job_doc)
                    ? null
                    : formik?.values?.job_doc_receive_date
                }
              >
                <DateTimePicker
                  name="job_doc_receive_date"
                  label={
                    Number(formik?.values?.job_doc)
                      ? "Receive Date"
                      : "Due Date"
                  }
                  formik={formik}
                />
              </CheckDateBeforeToday>
            </Stack>

            <Stack>
              <FormControlLabel
                control={<Checkbox />}
                label="Tax Document"
                checked={Number(formik?.values?.tax_doc) ? true : false}
                onChange={(e) => {
                  formik.setFieldValue("tax_doc", e.target.checked ? 1 : 0);
                }}
              />

              <CheckDateBeforeToday
                givenDate={
                  Number(formik?.values?.tax_doc)
                    ? null
                    : formik?.values?.tax_doc_receive_date
                }
              >
                <DateTimePicker
                  name="tax_doc_receive_date"
                  label={
                    Number(formik?.values?.tax_doc)
                      ? "Receive Date"
                      : "Due Date"
                  }
                  formik={formik}
                />
              </CheckDateBeforeToday>
            </Stack>
            <Stack>
              <FormControlLabel
                control={<Checkbox />}
                label="English language Proficiency  Document"
                checked={Number(formik?.values?.elp_doc) ? true : false}
                onChange={(e) => {
                  formik.setFieldValue("elp_doc", e.target.checked ? 1 : 0);
                }}
              />

              <CheckDateBeforeToday
                givenDate={
                  Number(formik?.values?.elp_doc)
                    ? null
                    : formik?.values?.elp_doc_receive_date
                }
              >
                <DateTimePicker
                  name="elp_doc_receive_date"
                  label={
                    Number(formik?.values?.elp_doc)
                      ? "Receive Date"
                      : "Due Date"
                  }
                  formik={formik}
                />
              </CheckDateBeforeToday>
            </Stack>
          </Box>
          {/* <Box sx={{ marginTop: "25px" }}>
            <Typography variant="h5">
              Progress: {formik?.values?.progress}%
            </Typography>
            <Box sx={{ padding: "5px" }}>
              <Slider
                value={formik?.values?.progress}
                min={0}
                step={1}
                max={100}
                onChange={(e, value) => {
                  formik.setFieldValue("progress", Number(value));
                }}
                valueLabelDisplay="auto"
                aria-labelledby="non-linear-slider"
              />
            </Box>
          </Box> */}
        </Box>
      )}
      <Box
        sx={{
          padding: "20px",
        }}
      >
        <Button
          variant="solid"
          onClick={formik.submitForm}
          fullWidth
          disabled={!(formik.dirty && formik.isValid) || loading}
        >
          {isUpdating ? "Update Project" : "Save Project"}
        </Button>
      </Box>
    </RightDrawer>
  );
};

export default AddEdit;
