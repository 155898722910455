import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Alert from "components/alert";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import PopoverAG from "components/ui/popoverAG";
import { updateDueDate } from "features/invoices/invoices/api/invoices";
import { deleteInvoice } from "features/projectCustomers/api/projectCustomer";
import useFormatter from "hooks/useFormatter";
import { setLoading } from "lib/Reducer/loadingSlice";
import moment from "moment";
import { Fragment, useState } from "react";
import { BsChevronExpand } from "react-icons/bs";
import { MdOutlinePayment } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Items from "./Items";
const InvoiceTable = ({
  loading,
  allInvoice,
  pagination,
  setPagination,
  setReload,
  setAddInvoice,
  setCurrInvoice,
  setAllInvoice,
  bgColor,
  setMakePayment,
  setViewInvoice,
}) => {
  const dispatch = useDispatch();
  const { formatDate } = useFormatter();
  const [openItems, setOpenItems] = useState([]);
  const rows = allInvoice;

  const columns = [
    { id: "invoice_number", label: "Invoice ID" },
    { id: "due_date", label: "Due Date", style: { width: "110px" } },
    { id: "due_amount", label: "Due Amount", style: { width: "120px" } },
    { id: "assignees", label: "Assigned", align: "center" },
    {
      id: "invoice_details",
      label: "Items",
      align: "center",
      style: { width: "140px" },
    },
    { id: "total", label: "Total", align: "right" },
    { id: "status", label: "Status", align: "center" },
    {
      id: "action",
      label: "Actions",
      style: { width: "150px" },
      align: "center",
    },
  ];

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = async (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    const result = await deleteInvoice({ id: openAlert });
    if (result.success) {
      toast.success(result.message);
      setAllInvoice(allInvoice.filter((invoice) => invoice.id !== openAlert));
    } else {
      toast.error(result.message);
    }
    setOpenAlert(false);
  };

  const handleEditBtn = (value) => {
    setCurrInvoice(value);
    setAddInvoice(true);
  };

  const handlePaymentBtn = (value) => {
    setCurrInvoice(value);
    setMakePayment(true);
  };

  const handleDueDate = async (id, due_date) => {
    dispatch(setLoading(true));
    try {
      const result = await updateDueDate({
        id,
        body_data: { due_date },
      });
      if (result.success) {
        toast.success(result.message);
        setReload((pre) => !pre);
      } else {
        toast.error(result.message);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Fragment>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Invoice"
          desc={
            <Box>
              Are you sure you want to delete? This will delete this Invoice
              permanently. <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 245px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, ...column.style }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => {
                const due_dateForCompare = moment(row?.due_date).format(
                  "YYYY-MM-DD"
                );
                const today = moment().format("YYYY-MM-DD");
                if (due_dateForCompare < today && row?.status !== "paid") {
                  bgColor = "#fff1f1";
                } else {
                  bgColor = "white";
                }
                return (
                  <>
                    <TableRow
                      hover
                      role="checkbox"
                      key={row.id}
                      style={{ backgroundColor: bgColor }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "date_added" ? (
                              value ? (
                                moment(value).format("ll")
                              ) : (
                                "-"
                              )
                            ) : column.id === "due_date" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "5px",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>{formatDate(value)}</span>
                                <LocalizationProvider
                                  dateAdapter={AdapterMoment}
                                >
                                  <DatePicker
                                    label="Custom input"
                                    value={value}
                                    onChange={(newValue) => {
                                      handleDueDate(
                                        row?.id,
                                        moment(newValue).format("YYYY-MM-DD")
                                      );
                                    }}
                                    renderInput={({
                                      inputRef,
                                      inputProps,
                                      InputProps,
                                    }) => (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {InputProps?.endAdornment}
                                        <input
                                          style={{
                                            width: "0px",
                                            height: "0px",
                                            opacity: 0,
                                          }}
                                          ref={inputRef}
                                          {...inputProps}
                                        />
                                      </Box>
                                    )}
                                  />
                                </LocalizationProvider>
                              </Box>
                            ) : column.id === "due_amount" ? (
                              row.symbol + (row.total - row.paid_amount)
                            ) : column.id === "total" ? (
                              <Typography color="#7171ff" variant="body2">
                                {row.symbol + value}
                              </Typography>
                            ) : column.id === "assignees" ? (
                              <PopoverAG items={value} />
                            ) : column.id === "invoice_details" ? (
                              value?.length ? (
                                <Box
                                  sx={{
                                    position: "relative",
                                    cursor: "pointer",
                                    "&:hover": {
                                      "& svg": {
                                        color: (theme) =>
                                          theme.palette.primary.main,
                                      },
                                    },
                                    "& svg": {
                                      position: "absolute",
                                      color:
                                        openItems === row?.id
                                          ? (theme) =>
                                              theme.palette.primary.main
                                          : "#777",
                                      top: "0px",
                                      right: "0px",
                                      cursor: "pointer",
                                      fontSize: "17px",
                                    },
                                  }}
                                  onClick={() => {
                                    setOpenItems(
                                      openItems === row?.id ? "" : row?.id
                                    );
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "150px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        overflow: "hidden",
                                        WebkitLineClamp: "1",
                                        WebkitBoxOrient: "vertical",
                                        textOverflow: "ellipsis",
                                        width: "100px",
                                        maxHeight: "22px",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {`${value[0]?.item}`}
                                    </Typography>
                                    {value.length > 1 && (
                                      <Typography>
                                        & {value?.length - 1}+
                                      </Typography>
                                    )}
                                  </Box>
                                  <BsChevronExpand />
                                </Box>
                              ) : (
                                "-"
                              )
                            ) : column.id === "action" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "8px",
                                  justifyContent: "center",
                                }}
                              >
                                <Tooltip title="View Payment">
                                  <IconButton
                                    onClick={() => setViewInvoice(row?.id)}
                                  >
                                    <TbListDetails />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Make Payment">
                                  <IconButton
                                    disabled={row.status === "paid"}
                                    onClick={() => handlePaymentBtn(row)}
                                  >
                                    <MdOutlinePayment />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit">
                                  <IconButton
                                    onClick={() => handleEditBtn(row)}
                                  >
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <IconButton
                                    onClick={() => handleDelete(row.id)}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={columns?.length}
                      >
                        <Collapse
                          in={openItems === row?.id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ margin: 1 }}>
                            <Box
                              sx={{
                                margin: "0 auto",
                                maxWidth: "580px",
                                zIndex: "-1",
                              }}
                            >
                              <Items
                                items={row.invoice_details}
                                symbol={row.symbol}
                              />
                            </Box>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 245px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Fragment>
  );
};

export default InvoiceTable;
