import ArrowBack from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useWindowSize from "hooks/useWindowSize";
import { useNavigate } from "react-router-dom";

const RightDrawer = ({
  openDrawer,
  setOpenDrawer,
  children,
  headingLeft,
  headingRight,
  width,
  variant = "temporary",
  nativeBack = false,
}) => {
  const { isMini, width: currWidth } = useWindowSize();
  const navigate = useNavigate();
  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      onClose={() => setOpenDrawer(false)}
      sx={{
        zIndex: "1000",
        "& .MuiBackdrop-root": {
          bgcolor: "rgb(0 0 0 / 15%)",
        },
      }}
      variant={variant}
    >
      <Box
        sx={{
          maxWidth: isMini ? currWidth : "100%",
          width: width ? width : "400px",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            height: "65px",
            px: "15px",
            bgcolor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.white.main,
          }}
        >
          <Grid item>
            <Grid
              container
              alignItems="center"
              gap="5px"
              sx={{ cursor: "pointer" }}
              onClick={() => (nativeBack ? navigate(-1) : setOpenDrawer(false))}
            >
              <ArrowBack style={{ fontSize: "20px" }} />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "400",
                  padding: "0 5px",
                  textTransform: "capitalize",
                }}
              >
                {headingLeft}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "14px" }}>{headingRight}</Typography>
          </Grid>
        </Grid>
        <Box sx={{ height: "calc(100vh - 65px)", overflowY: "auto" }}>
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

export default RightDrawer;
